body {
  background: #e5f4e1 !important;
}

.blue-font {
  color: #3b3b5f;
}

.red-font {
  color: #ff2222;
}

.wrapper-container {
  padding: 2.5% 10%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner-wrapper-container {
  width: 80vw;
}

.form-container {
  margin: auto;
  transition: 0.1s;
}

.form-fade-out {
  opacity: 0;
}

.form-fade-in {
  opacity: 1;
}

.inner-form-container {
  width: 66.666vw !important;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .inner-form-container {
    width: 80vw !important;
  }
}

.progress-indicator-container {
  width: 75vw !important;
  margin-left: 0vw;
}

.MuiLinearProgress-buffer {
  height: 8px !important;
  border-radius: 8px !important;
}

.intro-graphic {
  width: 580px;
  height: 434px;
  background-image: url('./assets/images/safe7-intro.png');
  background-size: cover;
  margin-top: calc(8.3333vh - 14px);
}

.safe7-radio-container {
  position: relative;
}

@media only screen and (max-width: 1320px) {
  .safe7-radio-container .MuiFormControlLabel-labelPlacementBottom {
    margin-left: 4px;
  }

  .safe7-radio-container .MuiFormControlLabel-root {
    margin-right: 4px;
  }
}

@media only screen and (max-width: 968px) {
  .safe7-radio-container {
    width: 20%;
  }
}

.hand-icon-graphic {
  width: 50px;
  height: 50px;
  background-image: url('./assets/images/hand-icon.png');
  background-size: cover;
  position: absolute;
  top: 16px;
  left: 28px;
  transform: scale(0.75);
}

@media only screen and (max-width: 1500px) {
  .intro-graphic {
    width: 480px;
    height: 360px;
    margin-top: 8.333vh;
  }
}

@media only screen and (max-width: 1200px) {
  .intro-graphic {
    width: 358px;
    height: 270px;
    margin-top: 16.666vh;
  }
}

@media only screen and (max-width: 960px) {
  .intro-graphic {
    width: 358px;
    height: 270px;
    margin-top: 0vh;
  }
}

.alert-box {
  color: #d34343;
  border: 1px solid #d34343;
  border-radius: 4px;
  max-width: 320px;
  margin: auto;
  padding: 10px;
  margin-bottom: 20px;
}

.submission-error-box {
  max-width: unset;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #7cb45d !important;
}

@media only screen and (max-width: 600px) {
  .indicator-container.inactive {
    display: none;
  }
}

.progress-indicator-number-icon {
  border-radius: 50%;
  margin-right: 10px;
  padding: 4px 8px;
  height: 32px;
  width: 32px;
  color: #fff;
  font-weight: 700;
}

.progress-indicator-number-icon.active {
  background: #7cb45d;
}

.progress-indicator-number-icon.inactive {
  background: #727b84;
}

.white-bg {
  background: #fff;
}

.action-bar {
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  background: rgba(221, 236, 217, 0.4222);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.action-bar-space {
  position: relative;
  height: 140px;
}

.action-bar-grid-container {
  background: #fff;
  max-width: 992px;
  margin: 0 auto;
  padding: 12px 24px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.btn-flash-on {
  box-shadow: 0px 0px 24px 0px #3b3b5f;
}

.btn-flash-off {
  box-shadow: 0px 0px 0px 0px #3b3b5f;
}
.action-bar-button,
.start-survey-btn {
  background: #3b3b5f !important;
  color: #fff !important;
  width: 100%;
  padding: 18px 0px !important;
  text-transform: capitalize !important;
  font-size: 1.25rem !important;
  transition: 0.2s;
}

.submission-error-button {
  width: 150px;
  padding: 10px !important;
}

.submission-error-button:first-of-type {
  margin-right: 10px;
}

.action-bar-button.inactive,
.start-survey-btn.inactive {
  background: transparent !important;
  color: #3b3b5f !important;
  border: 2px solid #3b3b5f !important;
}

.start-survey-btn {
  padding: 12px 0px !important;
  max-width: 320px;
}

.paragraph-container {
  max-width: 992px;
  width: 100%;
  margin: auto;
}

.decoration-container {
  color: #315bbf;
  border: 2px solid #315bbf;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.decoration-container span {
  font-size: 2.5em;
  font-family: serif;
  text-align: center;
  margin-left: 18px;
  line-height: 48px;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .decoration-container span {
    font-size: 1.5em;
    margin-left: 9px;
    line-height: 0px;
  }

  .decoration-container {
    width: 26px;
    height: 26px;
  }
}

.decoration-line {
  border-right: 4px solid #315bbf;
  height: 100%;
}

.more-info-button {
  text-transform: capitalize !important;
  color: #315bbf !important;
  font-weight: 700 !important;
  font-size: 1.25em !important;
}

.DayPicker {
  float: right;
  margin-right: 15px;
  transition: 0.15s;
}

.DayPicker.inactive {
  opacity: 0.3;
  filter: grayscale(1);
}

.DayPicker.inactive:hover {
  opacity: 1;
  filter: grayscale(0);
}

.DayPicker-Caption {
  background: #315bbf;
  padding: 12px 8px !important;
  color: #fff !important;
  margin-bottom: 0 !important;
}

.DayPicker-Month {
  background: #fff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 5px;
  border: 7px solid #fff;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background: #3b3b5f !important;
  border-radius: 4px;
  color: #fff !important;
}

.DayPicker-wrapper {
  margin-top: -7px;
}

.DayPicker-Day {
  color: #3b3b5f !important;
}

.DayPicker-Day.DayPicker-Day--today {
  background: transparent !important;
  color: #3b3b5f !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #3b3b5f !important;
  color: #fff !important;
  border-radius: 12.5% !important;
}

.DayPicker-NavButton {
  filter: brightness(5);
  margin-top: 14px !important;
}

.selected-date-container {
  border-left: 4px solid #3b3b5f;
  padding-left: 14px;
}

.do-not-know-button {
  width: 120px !important;
  height: 120px !important;
  border: 1px solid #aaaaaa !important;
  background: #fff !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: 0.15s !important;
  text-transform: capitalize !important;
}

.do-not-know-button .MuiButton-label {
  flex-direction: column;
}

.do-not-know-button.active {
  background: #c5d8df !important;
  border: 2px solid #3b3b5f !important;
}

.form-input-container {
  position: relative;
}

.complete-indicator-container {
  position: absolute;
  top: calc(50% - 14px);
  right: 14px;
  color: #7cb45d;
  transform: scale(1.777);
  animation: fadeIn 0.18s ease-in;
  opacity: 1;
}

.complete-indicator-container.more-right {
  right: -54px;
}

@media only screen and (max-width: 600px) {
  .complete-indicator-container {
    top: -35px;
    right: 4px;
  }

  .complete-indicator-container.more-right {
    right: -30px;
    top: 50%;
  }

  .mobile-flex {
    display: flex;
    justify-content: center;
  }

  .DayPicker {
    margin: 0;
  }

  .do-not-know-button {
    height: 60px !important;
    position: relative !important;
    top: -6px !important;
  }

  .do-not-know-icon-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  .qmark-icon {
    width: 12px !important;
    height: 24px !important;
  }
}

.custom-unchecked-icon-graphic {
  width: 32px;
  height: 32px;
  background-image: url('./assets/images/checkbox-not_selected.svg');
  background-size: contain;
}

.custom-checked-icon-graphic {
  width: 32px;
  height: 32px;
  background-image: url('./assets/images/checkbox-selected.svg');
  background-size: contain;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    right: 14px;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    right: 14px;
    transform: scale(1.777);
  }
}

.qmark-icon {
  width: 18px;
  height: 38px;
  margin: auto;
  background-image: url('./assets/images/survey/survey-assets/icon-qmark.svg');
  background-size: contain;
}

.toggle-group-icon {
  margin: 0 auto;
  background-size: cover;
  display: block;
}

.male-icon {
  width: 39px;
  height: 38px;
  background-image: url('./assets/images/survey/survey-assets/icon-male.svg');
}

.female-icon {
  width: 26px;
  height: 38px;
  background-image: url('./assets/images/survey/survey-assets/icon-female.svg');
}

.car-icon {
  width: 48px;
  height: 38px;
  background-image: url('./assets/images/survey/survey-assets/icon-car.svg');
}

.taxi-icon {
  width: 48px;
  height: 42px;
  background-image: url('./assets/images/survey/survey-assets/icon-taxi.svg');
}

.friend-icon {
  width: 48px;
  height: 39px;
  background-image: url('./assets/images/survey/survey-assets/icon-family_friend.svg');
}

.ticket-icon {
  width: 44px;
  height: 38px;
  background-image: url('./assets/images/survey/survey-assets/icon-public_transport.svg');
}

.hospital-icon {
  width: 38px;
  height: 38px;
  background-image: url('./assets/images/survey/survey-assets/icon-hospital.svg');
}

.qmark-icon {
}

.toggle-option-container {
  width: 100%;
  max-width: 762px;
  margin: auto;
}

.toggle-option-label {
  display: block;
  width: 100%;
}

.toggle-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 54px;
  padding: 0 5px;
}

.toggle-box-icon {
  height: 135px;
}

.MuiToggleButtonGroup-root {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-around !important;
}

.MuiToggleButtonGroup-root button {
  padding: 0 !important;
  height: 100% !important;
  border-radius: 4px !important;
  border: 1px solid #cacaca !important;
  width: calc(33% - 16px);
  margin-bottom: 14px;
  background: #fff !important;
  color: #3b3b5f !important;
  text-transform: capitalize !important;
}
.MuiToggleButtonGroup-root button.wrap-two-columns {
  width: calc(50% - 16px);
}

.MuiToggleButtonGroup-root button span {
  /* line-height: 4em; */
}

.toggle-group-two-column.MuiToggleButtonGroup-root {
  justify-content: center !important;
}

.toggle-group-two-column button {
  margin: 0px 20px;
}

.MuiToggleButtonGroup-root button.Mui-selected {
  background: #3b3b5f !important;
  color: #fff !important;
}

.MuiToggleButtonGroup-root button.Mui-selected .toggle-group-icon {
  filter: brightness(4);
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #3b3b5f !important;
}

.theme-input {
  background: #fff !important;
  width: 100%;
  max-width: 660px;
}

.checklist .MuiFormControlLabel-root {
  display: block;
  font-size: 1.5em !important;
}

.checklist {
  max-width: 580px;
  margin: auto;
}

.info-checklist {
  display: inline-block;
  float: right;
  width: 24px;
  height: 24px;
  margin-right: 56px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: -36px;
  background: #315bc0;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
}

.more-info-checklist-container {
  background: #fff;
  padding: 12px;
  border-radius: 2px;
  width: calc(100% - 72px);
}

.safe7-radio-container label span {
  padding: 0 !important;
}

@media only screen and (max-width: 600px) {
  .safe-seven-radio.MuiSvgIcon-fontSizeLarge {
    font-size: 3.5rem !important;
  }
}

.safe-seven-radio.MuiSvgIcon-fontSizeLarge {
  font-size: 3.5rem;
}

.safe-seven-radio-unchecked.safe-seven-radio.MuiSvgIcon-fontSizeLarge {
  color: #c5d8df;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #3b3b5f !important;
}

.safe-seven-radio-checked {
  color: #7cb45d;
}

.safe7-graphic {
  width: 390px;
  margin: auto;
  height: 278px;
  background-size: cover;
}

.safe7-graphic-1 {
  background-image: url('./assets/images/survey/survey-assets/illustration-q1.svg');
}

.safe7-graphic-2 {
  background-image: url('./assets/images/survey/survey-assets/illustration-q2.svg');
}

.safe7-graphic-3 {
  background-image: url('./assets/images/survey/survey-assets/illustration-q3.svg');
}

.safe7-graphic-4 {
  background-image: url('./assets/images/survey/survey-assets/illustration-q4.svg');
}

.safe7-graphic-5 {
  background-image: url('./assets/images/survey/survey-assets/illustration-q5.svg');
}

.safe7-graphic-6 {
  background-image: url('./assets/images/survey/survey-assets/illustration-q6.svg');
}

.safe7-graphic-7 {
  background-image: url('./assets/images/survey/survey-assets/illustration-q7.svg');
}

@media only screen and (max-width: 960px) {
  .safe7-graphic {
    width: 260px;
    height: 185px;
    margin-top: 0vh;
  }
}

.heart-graphic {
  background-image: url('./assets/images/survey/survey-assets/icon-heart.svg');
  width: 125px;
  margin: auto;
  height: 123px;
  background-size: cover;
  margin-top: 8.33333vh;
}

.bg-plants-graphic {
  width: 100vw;
  background-image: url('./assets/images/survey/survey-assets/bg_plants.svg');
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  height: 100vh;
  background-size: cover;
}

.language-input {
  background: #fff;
  float: right;
  height: 36px;
}

@media only screen and (max-width: 768px) {
  .MuiFormControlLabel-labelPlacementBottom {
    margin-left: 0 !important;
  }

  .DatePicker {
    width: 100%;
  }
}

.title-container {
  max-width: 600px;
  margin: auto;
}

.flag-icon {
  width: 0.75em;
  height: 0.75em;
  margin-right: 12px;
  position: relative;
  top: 0px;
  transform: translateY(2px);
}
